<template>
  <div id="krave-about-us" class="w-100 position-relative d-flex">
    <div id="about" class="w-100 about position-absolute flex-grow-1">
      <parallax>
        <img src="/assets/img/krave-studios-homepic.jpg" alt="" />
      </parallax>
    </div>
    <div id="about-page" class="w-100 about-page text-center position-absolute flex-grow-1">
      <div id="about-text" class="m-auto">
        <h4 class="text-center text-white about-h">
          KRAVE Studios is a cutting edge, full service creative production
          agency agency specializing in commercial campaign production and
          advertisement creation for leading brands and talent across various
          industries throughout the globe.
        </h4>
        <h4 class="text-center text-white about-h">
          With locations in LA, NYC and Miami we put a special focus on staying
          connected, authentic and distinctive to each client.
        </h4>
        <h4 class="text-center text-white about-h">
          We work with brand clients such as Nike, American Airlines, PacSun,
          Fox, Fox, Casper, Desigual, Budweiser, Adidas, Perrier Jouet, Nobu,
          Casamigos, Marriott, Calzedonia, and Absolut, executing these
          productions all around the world.
        </h4>
        <h4 class="text-center text-white about-h">
          We understand that brands no longer influence people. People do. Krave
          Studios works with both individuals and agencies to not only create
          compelling commercials, but also pair influencers, models and other
          talent with the projects, brands and non-profits they can impact the
          most.
        </h4>
      </div>
    </div>
  </div>
</template>
<script>
import Parallax from "vue-parallaxy";
export default {
  name: "About",
  components: {
    Parallax
  },
  created() {
    this.$store.commit("SET_FOOTER", false);
  },
  methods: {}
};
</script>
<style scoped>
@import "../css/About.scss";
</style>
